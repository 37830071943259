.privateClass {
  font-size: 12px;
  color: #161616;
  font-style: italic;
}
.iconstyle {
  color: #e32b16 !important;
  size: 24px;
  //   padding-left: 550px;
}
.shortlyClass {
  //   padding-left: 486px;
  font-weight: bold;
}
.realClass {
  //   padding-left: 365px;
}
.advisorsClass {
  //   padding-left: 387px;
  line-height: 0px;
}
.telephoneClass {
  margin-top: 5rem;
}
.personTable {
  width: 175%;
  padding-top: 10px;
}
.mortgagesTable {
  padding-top: 10px;
  width: 80%;
}
.tableGridClass {
  display: flex;
  justify-content: space-between;
}
.error-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
