
.franchiseNote{
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
}
.franchiseProfileContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 0;
}
.profileClass{
    margin: 0;
    font-size: 1.5rem;
}
.ag-react-container{
    height: 100%;
    width: 100%;
    padding: 0 2px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.gridButton{
height: 20px !important;
display: flex !important;
align-items: center !important;
padding-right: 3px;
}
.resetPassword {
   padding:0 29px; 
}
.buttonContainer{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.dataGrid {
 height:initial !important;
   .ag-body-viewport {
      // height: 100%;
       min-height: 0 !important;
      // overflow-y: hidden;
   }
   .ag-header-cell-text{
    margin: 0 !important;
}
 .ag-header-cell-label, .ag-cell{
    padding:0 !important;
    display: flex !important;
    justify-content: center !important;
}
.ag-header-cell{
    padding: 0 !important;
}
  
    .ag-cell {
     display: flex;
      align-items: center;
    }
}
.errorClass{
    color:#D65513;
    text-align: left;
    display: block;
    font-weight: bold;
    height: 30px;
}
.franchiseHeaderClass{
    display: flex;
    flex-direction: column;

}
.disableCellClass{
    height: 50px;
    line-height: 50px;
}
.editActionClass{
    height: 100%;
    display: flex;
    align-items: center;
}
.franchiseNameClass{
    display: flex !important;
   flex-direction: column !important;
     text-align: left !important;
    justify-content: flex-start;
   word-break: break-word !important;
  }
.emailCellClass{
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.profileContainer{
    padding-top: 22px !important;
}
.paginationClass{
    display: flex;
    justify-content: flex-end;
}
.saveButtonClass{
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 37px;
 }