.dropdownbankers{
    display: inline-block;
    width: 43%;
}
.dropdownstatus{
    display: inline-block;
    width: 43%;
    margin-left: 0 !important;
}
.request{
    display: inline-block;
    width: 26%;
}

.online-data-grid {
    display: contents;
}
.dataGridAdminDashboard {
 .ag-header-cell-label, .ag-cell{
        padding:0 !important;
        display: flex !important;
        justify-content: center !important;
    }
   }
.paginations, .formFields {
    margin-top: 20px;
    display: flow-root;
    margin-bottom: 25px;
} 
.formFields {
    margin-top: 30px;
    } 

.dollar{
    display: inline-block;
    width: 36%;
    margin-left: 23px;
}

.requestdate{
    display: inline-block;
}

.todate{
    display: inline-block;
    margin-left: 19px;
}

.datesfields{
    padding-left: 8px;

}

.ag-theme-alpine{
    margin-top:20px;
}

.adv-search{
    margin-top: 20px !important;
}
.dropDownField span {
    font-size: 14px;
    font-weight: bold;
}
.franchiseeNameCell{
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.onlineRequestPurposeClass{
    display: flex !important;
   flex-direction: column !important;
     text-align: left !important;
    justify-content: flex-start;
   word-break: break-word !important;
  }

  .bankerClass {
    margin: 0px !important; 
    padding: 2px !important;
    max-width: initial !important;
    textarea, .txtBold {
      font-weight: bold;
      font-size: 14px;
  }
  }
  .modalContainer{
    display: flex;
    gap: 25px 10px;
    flex-wrap: wrap;
   >div:nth-child(1),>div:nth-child(2) {
        flex:1 1 47%;
        }
       
            >div:nth-child(3) {
                flex:1 1 100%;
                }
}
 .dialogClass{
     margin-top: 0 !important;
     padding-top: 0 !important;
 }

 .dialogStyle {
  padding:20px!important;
  margin: 0px !important;
}

.dialogContent {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.dsFlex {
    display: flex;
}

.fltRght {
    float: right;
}
.editLink{
    margin-left: 20px !important;
}
