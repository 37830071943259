
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerContainer{
    padding: 20px 0;
}

.comments textarea{
    font-weight: 700;
    font-size: 14px;
}

.comments textarea::-webkit-input-placeholder{
    font-weight: 400;
    font-size: 13px;
}

.MuiDialogTitle-root {
    font-size: 16px !important;
    margin-bottom: 15px !important;
}

.headingClass {
    font-size: 25px;
}

.nestedThirdFormContainer .headingClass {
    font-size: 20px;
}
.debt-noteSection{
    color: #4c505b;
    font-weight: 400;
    font-size: 14px;
    // margin-top: 262px;
}
