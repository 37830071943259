.restuarantHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px !important;
  margin-top: 60px;
}
.restuarantFooterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setMarginClass {
  margin-top: 20px;
}
.singleRowRadioBtn {
  flex-direction: row !important;
  justify-content: flex-start !important;
  & > div {
    padding-right: 15px;
    white-space: initial;
  }
}
.updateButtonClass {
  margin-left: 2px;
}
.restContainer .nestedFormContainer {
  padding: 12px 8px 10px 60px;
}

.footerBtnPurchaseContainer {
  padding: 47px 0px 22px 0px;
  position: relative;
}

.footerBtnContainer {
  position: relative;
  margin-top: 1rem;
}

.parentShContainer {
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-content: end;
}

.addOldStoreContainer {
  padding: 0 !important;
  position: relative;
}

.addNewStoreContainer {
  padding: 0 !important;
  position: relative;
}

.debtListValue input {
  cursor: not-allowed;
  background-color: red;
}

.leaseOptions textarea,
.boldText textarea {
  font-weight: 700;
  font-size: 14px;
}

.leaseOptions textarea::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 13px;
}

.formClass .dropDownField span {
  font-size: 14px;
  font-weight: bold;
}

.MuiDialogTitle-root {
  font-size: 16px !important;
  margin-bottom: 15px !important;
}
.headingClass {
  font-size: 25px;
}

.nestedFormContainer .headingClass {
  font-size: 20px;
}
.shareholderHeaderContainer {
  margin-top: 0 !important;
  padding-right: 0 !important;
}
.updateClass {
  color: rgba(76, 80, 91, 1);
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  // margin-bottom: 20px;
  // margin-top: 15px;
}

.setMargin {
  margin-top: 44px !important;
}
.nestedFormContainer #header0 {
  margin-top: 5px !important;
}
.updateNoteStockClass {
  color: rgba(76, 80, 91, 1);
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  margin: 1rem 0 0 0;
}

.updateBtn {
  position: absolute !important;
  right: 0;
}

.oldStoreTopContainer h3,
.newStoresTopContainer h3 {
  cursor: default;
}

.numberOfRest {
  color: #2670a9;
}

.addressToast,
.borrowerNameToast {
  margin: auto 0 1rem auto;
  margin-top: 3rem;
}
.noShareholdersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addRequired::before {
  content: "**";
  color: #d65513;
  padding-left: 10px;
  font-size: 13px !important;
}
