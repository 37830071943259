.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerContainer {
  padding: 20px 0 20px 0;
}

.comments textarea {
  font-weight: 700;
  font-size: 14px;
}

.comments textarea::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 13px;
}

.dropdownloan {
  width: 100%;
  display: block;
}

.desired-term {
  font-size: 11px;
  color: #61656e;
  padding: 0 16px;
  margin-bottom: 0;
  width: 100%;
}

.subtext {
  font-size: 13px;
  color: #61656e;
  margin-top: 0;
  width: 100%;
}
.helperText {
  margin: 0;
  padding: 0 4px;
  font-size: 12px;
}

.asap-checkbox {
  margin-right: 113px;
  margin-top: 14px;
  display: flex;
  align-items: baseline;
  p {
    margin-right: 1rem;
  }
}

.radiobtnpadding {
  padding: 8px;
}
.pad-bottom {
  padding-bottom: 20px;
}
