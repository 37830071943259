
.physical-mailing-address{
    input,textarea{
        font-size:14px;
        font-weight:bold
    }
    .dropDownField span{
      font-size:14px;font-weight:bold
    }
     
      .errorHelperText{
        color:#D65513 !important
      }

.singleRowRadioBtn{
  flex-direction: row !important;
  justify-content: flex-start !important;
}
.prvsection{
  margin-left: 0px !important;
}

.physical-buttonContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
}


.nextsection-btn{
  margin-bottom: 63px;
  .nextsection{
    margin-left: 432px !important;
  }
}
.physical-note{
  color: #4c505b;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  }

      
}




