.ckeditorStyle {
  font-size: 10.5px;
  padding-left: 9px;
}
.line {
  display: inline-block;
  width: 100%;
  border-top: 3.5px solid #b2beb5;
}

.centerPosition {
  margin-top: 30px;
  position: relative;
  right: 8px;
}
.updateheadingClass {
  color: #161616;
  position: relative;
  bottom: 6px;
}
.successAlert {
  color: green;
  font-weight: bold;
}
.failureAlert {
  color: #d65513;
  font-weight: bold;
}
.alertmsg {
  text-align: left;
  display: block;
  font-weight: bold;
  margin-bottom: 2rem;
}
