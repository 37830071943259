.searchButtonsClass{
    margin-top: 27px;
    margin-bottom: 27px;
   
}
.searchClass{
    font-weight: 600;
}
.franchiseNote{
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
}
.searchFranchiseGrid {
    height:initial !important;
      .ag-body-viewport {
         // height: 100%;
          min-height: 0 !important;
         // overflow-y: hidden;
      }
   .ag-header-cell-text{
        margin: 0 !important;
    }
     .ag-header-cell-label, .ag-cell{
        padding:0 !important;
        display: flex !important;
        justify-content: center !important;
    }
     }
.paginationSearchClass{
    display: flex;
    justify-content: flex-end;
    margin-top: 22px !important;
}
