//Home page stlying
// @media screen and (max-width:auto){
.home {
  position: relative;
}
.bgc {
  // background: #E5E5E5;
  display: flex;
  flex-direction: row;
  width: 100%;
}
// }
.content-1 {
  flex: 1;
  text-align: left;
  margin-top: 1rem;

  .box {
    width: 19rem;
    height: 14.7rem;
    background-color: #c4c4c4;
  }
}
.homeHeader1 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: capitalize;
  color: #2670a9;
  margin-bottom: 4px;
}
.homeHeader1 {
  margin-bottom: 3px;
}
.contenth1 {
  font-style: normal;
  font-weight: normal;
  font-size: 0.87rem;
  line-height: 1.2rem;
  color: #4c505b;
  margin-top: 0px;
}
.content-2 {
  flex: 2;
  padding-left: 3rem;
  .homeHeader2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: capitalize;
    color: #131314;
    margin-bottom: -0.3rem;
  }
  .contenth2 {
    font-size: 0.94rem;
    line-height: 1.25rem;
    color: #4c505b;
    margin-bottom: -1rem;
  }
  .find {
    height: 1.25rem;
    font-size: 0.94rem;
    line-height: 1.25rem;
    text-decoration-line: underline;
    color: #4c505b;
    cursor: pointer;
  }
  .linkContent {
    font-size: 0.94rem;
    line-height: 1.25rem;
    color: #4c505b;
    margin-bottom: 0.4rem;
  }
  .button {
    height: 1.75rem;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
    // height: 18px;
    width: 13.4rem;
    border: none;
    background-color: #2670a9;
    display: inline-block;
    cursor: pointer;
  }
}
.home-container {
  padding: 0;
}
.home-content {
  padding-top: 45px;
  bottom: 0;
  line-height: 1.18rem;
  font-size: 14px !important;
  div.homeBanner {
    padding-bottom: 0px;
    word-wrap: break-word;
  }
  p {
    //color: #000;
    font-size: 14px !important;
  }
  h2 {
    font-size: 14px !important;
  }
  h3 {
    font-size: 14px !important;
  }
  h4 {
    font-size: 14px !important;
  }
  ul {
    font-size: 14px !important;
  }
  ol {
    font-size: 14px !important;
  }
  .content6 {
    margin-bottom: -0.5rem;
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .home-content {
    margin-top: 17rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .home {
    padding: 10px 29px 20px;
  }
  .home-content {
    margin-top: 10rem;
    margin-bottom: 0rem;
    padding: 0px 0px 0px 0px;
    position: relative;
    bottom: 143px;
  }
  .bgc {
    padding-top: 0px;
  }
  .find {
    font-size: 12px !important;
  }
  .homeHeader1 {
    font-size: 1rem !important;
  }
  .homeHeader2 {
    font-size: 1rem !important;
  }
  .contenth2 {
    font-size: 12px !important;
    line-height: 1rem !important;
  }
  .contenth3 {
    line-height: 1rem;
    margin-top: 0px;
  }
  .content-2 {
    padding-left: 0rem !important;
  }
  .linkContent {
    font-size: 12px !important;
  }
  .Link-root-286 {
    font-size: 12px !important;
  }
  .box {
    width: 18rem !important;
    height: 13.7rem !important;
  }
  .contenth1 {
    font-size: 10px !important;
  }
  .footer-content {
    margin-top: 0rem;
    //position: relative !important;
    /* top: -111px; */
    //bottom: 205px !important;
  }
}

@media screen and (max-width: 768px) {
  .bgc {
    flex-direction: column;
    .content-2 {
      padding-left: 0;
    }
  }
  .home-content {
    margin-top: 5rem;
  }
}
.homebanner span {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
  margin-bottom: -20px;
  position: relative;
  bottom: 13px;
}
