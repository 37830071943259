//footer styling
.footer-content {
  display: flex;
  flex-direction: column;
  // position: absolute;
}
.para {
  /* width: 85%;
    padding-left: 7.5rem; */
  // position: absolute;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: Open Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* Gray secondary */
  color: #4c505b;
}
.footer {
  height: 72px;
  border-radius: 0px;
  background-color: #4c505b;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    height: 100%;
    flex-direction: column;
  }
}
.footer-1 {
  flex: 1;
}

.content {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  /*  padding-left: 7rem;
    padding-top: 1rem;
    width:25rem */
}

.footer-2 {
  display: flex;
  /*     flex: 2;
 */
  flex-direction: column;
  /* justify-content: flex-end; */
  /* padding-right: 4rem; */
  font-family: Open Sans;
  font-size: 0.87rem;
  line-height: 1rem;
  color: #ffffff;
  /*  padding-top: 1rem; */
  .list-1 {
    ul {
      list-style-type: none;
      float: right;
      li {
        float: left;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      a {
        text-decoration: none;
        cursor: pointer;
        color: #ffffff;
      }
    }
  }
  .list-2 {
    /* flex:1; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    ul {
      list-style-type: none;
      li {
        float: left;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        a {
          text-decoration: none;
          cursor: pointer;
          color: #ffffff;
        }
      }
    }
  }
}
.vector {
  width: 0px;
  height: 1rem;
  border: 1px solid #e0d6d6;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;
  display: inline-block;
}
