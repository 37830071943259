.contactmailaddress {
  address:nth-child(1) {
    font-weight: bold;
  }

  list-style: none;
  line-height: 20px;
  margin-top: 33px;
  margin-bottom: 34px;
}
.addressClass {
  font-style: normal !important;
}
.contactaddress {
  address:nth-child(2n - 1) {
    font-weight: bold;
  }

  margin-top: 33px;
  list-style: none;
  line-height: 20px;
}
.contact-financial {
  line-height: 18px;
  margin: 15px;
  font-size: 14px;
  padding: 10px 0;
}
.usercontact {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

.usermessage {
  font-size: 12px;
  margin-top: 0;
  margin-right: 6px;
}
.usercall {
  margin-right: 6px;
}
.verticalAlignInherit {
  vertical-align: inherit;
}
