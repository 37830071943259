
    table.infoTable,thead,table.infoTable td{
        border : 2px solid #aaaaaa;
        border-collapse: collapse;
        height: 60px;
        padding: 10px;
        vertical-align:top;
        .text-center{
            text-align: center;
            vertical-align:middle;
        }
    } 
    table.infoTable th {
        border: 2px solid #aaaaaa;
        padding:10px;
        font-weight:normal;
        vertical-align: top;
        text-align: left;
    }
    table.infoTable td:not(:first-child) {
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        vertical-align: middle;
        font-weight: bold;
    }
