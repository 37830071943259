.contactformClass {
  input {
    font-size: 14px;
    font-weight: bold;
  }
}
.textFont {
  font-size: 14px !important;
  font-weight: bold !important;
}

.textFont.textBold .MuiChip-clickable {
  font-weight: bold;
  font-size: 14px;
}
.paraContactInfoClass {
  line-height: 9px;
}
.marginTopssn {
  margin-top: -15px !important;
}

.securityRequired::before {
  content: "**";
  color: #d65513;
  padding-left: 10px;
  font-size: 13px !important;
}

.spanclass-second {
  margin-left: -16px;
  float: left;
}
.dateRequired::before {
  content: "*";
  color: #d65513;
  margin-left: -15px;
  font-size: 13px !important;
}
.nextClass {
  margin-bottom: 12px;
}
.contentFont {
  font-style: normal;
  line-height: 0.8rem;
  color: #161616;
  font-size: 13px;
  font-weight: bold;
}
.spaceText {
  margin-top: 20px;
}
.errorHelperText {
  color: #d65513 !important;
}
.noteInfoClass {
  color: rgba(76, 80, 91, 1);
  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
}
#statedropDownField span {
  font-size: 14px;
  font-weight: bold;
}
.footerInfoClass {
  margin-top: 30px;
}
.noteSectioncontactinfo {
  color: #44484f;
  padding: 4px;
  font-size: 14px;
  margin-top: 25px;
  padding-bottom: 0px;
}
.mandatorycontactInfo {
  color: #d65513;
}

.fullwidthCountry div {
  max-width: 100% !important;
}

.contactonerequire::before {
  content: "*";
  color: #d65513;
  display: inline;
  padding-right: 15px;
  font-size: 13px;
}
.contactrequire::before {
  content: "**";
  color: #d65513;
  display: inline;
  padding-right: 10px;
  font-size: 13px;
}
.reqfieldcontact label {
  margin-left: -25px;
}
.backColor {
  background-color: rgb(222, 222, 222);
}
.marginSetup {
  margin-bottom: 8px !important;
}
.contactheadingClass {
  color: #161616;
}
