
    table.staticTable,thead,table.staticTable td{
        border : 2px solid #aaaaaa;
        border-collapse: collapse;
        height: 60px;
        padding: 7px 7px 7px 17px;
        vertical-align:top;
        .text-center{
            text-align: center;
            vertical-align:middle;
        }
    } 
    .loan-summary-payments{
        list-style: circle;
    }
    table.staticTable td:first-child{
        font-weight:bold;
    }
    table.staticTable th {
        border: 2px solid #aaaaaa;
        text-align: center;
        padding:13px 0;
    }
    table.staticTable ul {
        padding-left:24px;
        margin-top:0;
    }