.data{
line-height: 4px;
color: #4C505B;
p{margin:0}
}
.faxnumber{    
line-height: 6px;
color: #4C505B;
p{margin:0}
}
.regular{
    font-weight: bold;
}
