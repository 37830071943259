.infoClass{
    color: rgba(76, 80, 91, 1);
    font-weight: 400;
    font-size: 11px;
    padding:0px 0px 0px 6px;
}
.buttonbarClass{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 12px !important;
}
.formClass {
    textarea::placeholder{
        font-weight:normal;
   
}
textarea {
       font-weight:bold;
       font-size: 14px;
    }
}


