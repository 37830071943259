.dropdownbankers {
  display: inline-block;
  width: 43%;
}
.dropdownstatus {
  display: inline-block;
  width: 43%;
  margin-left: 23px;
}

.request {
  display: inline-block;
  width: 26%;
}

.dollar {
  display: inline-block;
  width: 36%;
  margin-left: 23px;
}

.requestdate {
  display: inline-block;
}

.todate {
  display: inline-block;
  margin-left: 19px;
}
.datesfields {
  padding-left: 8px;
  margin-top: 17px;
  margin-bottom: 19px;
}

.ag-theme-alpine {
  margin-top: 20px;
}
.adv-search {
  margin-top: 10px !important;
}
.gridButton {
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.purposeClass {
  display: flex !important;
  flex-direction: column !important;
  // align-items: center !important;
  justify-content: flex-start !important;
}
.buttonLabelClass {
  font-weight: bold !important;
  font-size: 14px !important;
}
.paginationClass {
  display: flex;
  justify-content: flex-end;
}
.dataGridAdminDashboard {
  height: initial !important;
  .ag-header-cell-text {
    margin: 0 !important;
  }
  .ag-header-cell-label,
  .ag-cell {
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
  }
  .ag-body-viewport {
    min-height: 0 !important;
  }
}
.adminDashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 0;
}
.dashboardHeader {
  margin-bottom: 0;
}
.requestContainer {
  //  padding-top: 30px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
