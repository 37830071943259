.return-to-dashboard-data-grid {
  // max-height: 300px;
  margin-top: 50px;
}

.rtd-username {
  margin-top: 46px;
}
.returnTodashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0 !important;
}

.preview-btn {
  font-weight: bold !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.preview-only-btn {
  font-weight: bold !important;
}
// .rtd-content{
//     margin-top: 2px;
//     margin-bottom: 26px;

// }
.rtd-content-banner {
  margin: 0px;
}
.rtd-filter {
  margin-bottom: 14px;
}
.cnr {
  background: #155c93 !important;
  color: aliceblue !important;
}
.edit-btn {
  background: #155c93 !important;
  color: aliceblue !important;
  font-weight: bold !important;
  text-decoration: none !important;
  font-size: 11px;
  padding: 1px 8px 2px 8px;
}
.options {
  position: absolute;
  top: 0%;
}

.cancel-btn {
  font-size: 10px;
  cursor: pointer;
}
.rtd-header,
.rtd-header3 {
  font-size: 20px;
  font-weight: initial;
  margin-bottom: 0px;
  margin-top: 18px;
}
.rtd-header3 {
  margin-left: 7px;
}
.rtd-header2 {
  margin-left: 7px;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 18px;
}
.crtnewbtn {
  text-decoration: none !important;
}
.success-banner {
  color: #24874b;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: 15px;
}
.create-new-request {
  margin-top: 0px;
}
.dashboard-pagination {
  float: right;
  margin-top: 10px;
}
.widthContent {
  width: 84% !important;
}
.returnPurposeClass {
  display: flex !important;
  flex-direction: column !important;
  text-align: left !important;
  justify-content: flex-start;
}
.returnContainer {
  display: flex !important;
}
