.headingClass{
    color: rgba(22, 22, 22, 1);
}
.alertClass{
    color: rgba(76, 80, 91, 1);
    font-weight: 400;
    font-size: 14px;
}
.formContainer {
    padding: 20px 500px 20px 150px;
}
.formClass {
  margin: initial !important; 
  max-width: initial !important; 
  input{
      font-size:14px;font-weight:bold
  }
  .dropDownField span{
    font-size:14px;font-weight:bold
}
.errorHelperText{
    color:#D65513 !important
}
}