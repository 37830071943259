.noteSectAlign {
  margin-bottom: 0px;
}
.business-address-info {
  .asterisk:before {
    content: " *";
    color: red;
    display: inline-block;
  }

  .business-header {
    font-size: 24px;
  }
  .business-header-add {
    font-size: 24px;
    margin-bottom: 0px;
  }

  .authorize {
    font-size: 14px;
    width: 70%;
  }

  .business-required {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 51px;
    margin-bottom: 16px;
    color: #000000;
  }

  #statedropDownField span,
  #countrydropDownField span {
    font-size: 14px;
    font-weight: bold;
  }

  .business-note {
    font-size: 14px;
    line-height: 16px;
  }

  .required {
    display: inline-block;
    width: 99% !important;
  }
  .nextsection {
    margin-left: 427px !important;
  }
  .prvsection {
    margin-left: 8px !important;
  }
  .nextsection-btn {
    margin-bottom: 76px;
  }
  // .FormField-controlArea-100 {
  //     width: 70% !important;
  //   }

  .errorHelperText {
    color: #d65513 !important;
  }

  input,
  textarea {
    font-size: 14px;
    font-weight: bold;
  }
  .business-buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px;
  }

  .dropDownField span {
    font-size: 14px;
    font-weight: bold;
  }
  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    margin: -1px !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}

.business_banner {
  margin-bottom: 1rem;
}

.addRequired::before {
  content: "**";
  color: #d65513;
  padding-left: 10px;
  font-size: 13px !important;
}
