.cursor {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.container h1 {
  color: #2670a9;
}
.authenticationContainer h1 {
  color: #2670a9;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.25rem;
}
a.bold {
  font-weight: bold;
}
.marginTop20 {
  margin-top: 20px;
}
.marginBottom30 {
  margin-bottom: 30px;
}
.marginTop30 {
  margin-top: 30px !important;
}
.marginBottom45 {
  margin-bottom: 45px;
}
.marginTop77 {
  margin-top: 77px;
}
.marginTop50 {
  margin-top: 50px;
}
.marginLeft8 {
  margin-left: 8px;
}
.marginTop70 {
  margin-top: 70px;
}
.padding8 {
  padding: 0px 8px;
}
.paddingLeft100 {
  padding-left: 100px;
}
.paddingRight96 {
  padding-right: 96px;
}
.paddingBottom50 {
  padding-bottom: 50px;
}
.paddingTop50 {
  padding-top: 50px;
}
.paddingLeft1 {
  padding-left: 1rem;
}
.paddingRight {
  padding-right: 2.5rem;
}
.secondaryNavButton {
  font-family: Open Sans;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.6000000238418579px;
  text-align: center;
  cursor: pointer;
  a {
    text-decoration: none;
  }
}
.secondaryNavButton button:hover {
  background: none;
}
.blockSpan {
  display: inline-block;
  width: 100%;
}
.noteSection {
  color: #4c505b;
  font-size: 14px;
  margin: 65px 0 60px;
}
.noteSectionClass {
  color: rgba(76, 80, 91, 1);
  font-weight: 400;
  font-size: 14px;
}
.borderRight {
  border-right: 2px solid #aaaaaa;
}
.padding0 {
  padding: 0 !important;
}
p {
  line-height: 20px;
}
.listItems {
  line-height: 20px;
}
.listStyleCustom {
  list-style-type: "- ";
}
.alignCenter {
  width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
}
.authenticationContainer {
  margin-top: 30px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.nestedFormContainer {
  padding: 40px 0px 20px 60px;
}

.nestedThirdFormContainer {
  padding: 20px 0px 20px 60px;
}

.nestedLoanFormContainer {
  padding: 0px 0px 20px 60px;
  .headingClass {
    font-size: 15px;
  }
}

.helpClass {
  margin-top: 45px !important;
}
.justifyCenter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px;
}
.existingUserClass {
  color: rgb(205, 76, 10);
  font-weight: bold;
}
.existingEmailClass {
  color: rgb(205, 76, 10);
  font-weight: bold;
}
.errormsg {
  color: rgb(205, 76, 10);
  font-weight: bold;
}
.buttonContainer {
  .floatLeft {
    float: left;
    width: fit-content;
  }
  .floatRight {
    float: right;
    width: fit-content;
  }
}
.commonStylingButton {
  margin-right: 20px !important;
}
.regularButton {
  width: 90px;
}
.ctaButton {
  width: 130px;
}
.copyAddressButton {
  margin-left: 20px !important;
}
.buttonContainer1 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  div:nth-of-type(1),
  div:nth-of-type(2) {
    flex: 1 1 10%;
  }
  div:nth-of-type(3) {
    flex: 1 1 70%;
  }
}
.dialogContainer .dialogcontentFont {
  font-size: 14px;
}
.mandatory {
  color: #d65513;
}
.mandatoryleftnav {
  pointer-events: none;
  cursor: not-allowed !important;
  color: rgba(68, 72, 79, 0.7) !important;
}
.search {
  height: 100% !important;
}
.searchButton {
  height: 100% !important;
  align-items: flex-end !important;
}
.dropdownContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .container {
    padding: 10px 20px 20px;
  }
  .dynamicContainer {
    padding: 40px 60px 55px;
    width: 53%;
  }
  .adminContainer {
    padding: 40px 38px 55px;
    width: 90%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .container {
    padding: 10px 115px 20px;
  }
  .dynamicContainer {
    padding: 40px 60px 55px;
    width: 53%;
  }
  .adminContainer {
    padding: 40px 38px 55px;
    width: 90%;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1920px) {
  .container {
    padding: 10px 150px 20px;
  }
  .dynamicContainer {
    padding: 40px 60px 55px;
    width: 53%;
  }
  .adminHomeContainer {
    width: 70% !important;
  }
  .adminContainer {
    padding: 40px 38px 55px;
    width: 70%;
  }

  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    .adminContainer {
      padding: 40px 38px 55px;
      width: 90%;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 1rem;
  }
  p {
    font-size: 12px;
  }
  h2 {
    font-size: 1.1rem;
  }
  li {
    font-size: 12px;
  }
  .paddingTop50 {
    padding-top: 0px;
  }
  .marginTop50 {
    margin-top: 0px;
  }
  .ratesTable td {
    font-size: 12px;
  }
  .ag-center-cols-container .ag-cell {
    margin-top: 1px;
  }
  table.staticTable {
    font-size: 12px;
    line-height: 1.12rem;
  }
  .infoTable {
    font-size: 9px;
    line-height: 0.9rem;
    position: relative;
    right: 100px;
  }
  .paddingLeft100 {
    padding-left: 0px;
  }
  .subHeading {
    font-size: 1rem !important;
  }

  .contactmailaddress {
    margin-top: 0px !important;
  }
  .contactaddress {
    margin-top: 0px !important;
  }
  .ratesTable th:first-child {
    font-size: 12px;
  }
  .ratesTable td {
    width: 60%;
  }
  #stand-alone {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .VerticalNavigation-mediumDensity-171.VerticalNavigation-root-158 {
    width: 200px;
  }

  .VerticalNavigation-root-178 {
    min-width: 230px !important;
  }

  .jss148 {
    flex-grow: 1;
    width: 60%;
  }
  .jss149 {
    width: 30%;
  }
}

@media screen and (min-width: 768px) {
  .Navigation-content-148 {
    width: 60%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .dynamicContainer {
    padding: 40px 60px 55px;
    width: 60%;
  }
}
@media screen and (min-width: 1921px) {
  .container {
    padding: 10px 150px 20px;
  }
}

.faqContent {
  padding-top: 0;
  margin-top: 0;
}

.formLinkStyle {
  display: block;
  margin-bottom: 6px;
}

.homeFindTable {
  th {
    text-align: left;
    width: 9%;
    vertical-align: top;
  }
}

.loanRequestClass {
  text-decoration: underline;
  font-family: "Open Sans";
}

.subLoanHeading {
  font-size: 24px;
  color: rgba(38, 112, 169, 1);
  font-weight: bold;
  cursor: pointer;
  text-decoration-color: rgba(38, 112, 169, 1);
}

.baseContainer {
  display: flex;
  min-height: 100%;
  .adjDynamic {
    display: flex;
  }
  .adjPosition {
    display: contents;
    div[class^="Navigation-content"] {
      display: none;
    }
  }
  .adjPosition > div:last-child {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .adjPosition .undefinedDensity > div:first-child {
    min-width: 265px !important;
  }
}

.searchFranchise .paginationClass {
  margin-top: 20px !important;
}

p.noteSectionClass {
  margin-top: 45px;
}

.noteSectAlign {
  margin-left: 10px;
  margin-bottom: 5px !important;
}

p.debt-noteSection {
  margin-top: 25px;
}

.cancelBtn {
  text-decoration: none;
  background: #ccc;
  padding: 3px 5px;
}
.nextSectionClass {
  margin-right: 0px !important;
}
