.ratesTable .info{
    font-size:12px;
    color: #161616;
    font-style: italic;
    width:150%;
}
.ratesTable{
    td{
        line-height: 20px; vertical-align: top; width:30% }
    th{
        line-height: 18px;   }
}
table.ratesTable th.width30{
    width:30%;
}
.ratesTable th:first-child{
    width: 28%;
    vertical-align: top;
    text-align:left;
}

