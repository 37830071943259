
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.noteSections {
    color: rgba(76, 80, 91, 1);
    font-weight: 400;
    font-size: 14px;
    margin-top: 35px; 
}
.noteSec {
    color: #44484F;
    padding: 4px;
    font-size: 12px;
    margin-top: 10px;
    
}

.footerContainers{
    padding: 20px 0px 0px 0px ;
    .noteSectio {
        color: #44484F;
        padding: 4px;
        line-height:25px;
        font-size:13px;
        font-weight:bold;
        margin-top: 30px;
    }
}


.require::before {
    content:"**";
    color: #D65513;
    display: inline;
    padding-left:10px;
    font-size: 13px ;
}

.requireds::before {
    content:"**";
    color:  #D65513;
    display: inline;
    font-size:12px;
   
}

.errorHelperText{
    color:#D65513 !important;
}
.dropDownField  {
    font-size: 14px;
    font-weight: bold;
}

.nestedThirdFormContainer .headingClass {
    font-size: 20px;
}
