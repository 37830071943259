.deleteButton{
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 30px
}
.nestedFormContainerClass{
    padding: 20px 0px 20px 30px;
}
.nestedOwnerDetailsClass{
    padding: 8px 0px 0px 60px
}
.addButtonClass{
    margin-left: 60px !important;
    padding: 20px 0 !important;;
}
.headingClass {
    font-size: 25px;
}
.nestedOwnerDetailsClass .headingClass {
    font-size: 20px;
}
.noteClass{
    font-size: 14px; 
    margin-top: 50px;
    color: #4C505B 
}
.buttonClass { 
    display: flex !important;
     justify-content: space-between !important;
   
}

