.headingClass{
    color: #161616;
}
.formClass {
    margin: initial !important; 
    max-width: initial !important; 
  }
 .noteClass {
     margin-top: 80px;
     color: #4C505B
 }
 .referralButton{
        display: flex !important;
         justify-content: space-between !important;
         margin-top: 30px
    }