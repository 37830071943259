html {
  height: 100%;
  font-family: Open Sans !important;
}
.skipLink {
  position: absolute;
  z-index: 100000000000;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Open Sans !important;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*   background-color: #f5f2f2; */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  height: 100%;
  /* // overflow: hidden; */
}
#root {
  height: 100%;
  max-height: 84%;
}
/*.articleClass{
 /* height: 100% 
 }*/
