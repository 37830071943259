.overlay{
    position: absolute;
    top:0;
    bottom:0;left:0;right:0;
    background-color: rgb(0,0,0);
    opacity:0.7;
    z-index:1100;
    height:auto;
    min-height: 143%;
}
.loaderBox{
    width: 17%;
    padding:30px;
    margin-top: 17%;
    margin-left:auto;
    margin-right:auto;
    background-color:white;
    z-index:1101;
    position:absolute;
    left:42%;
    text-align: center;
    .spinner {
        display: inline-block;
    }
}