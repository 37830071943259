.sesssion-warning{
    color:orangered !important;
   
}
.session-dialogboxclose{
    margin-left: 222px!important;
    cursor: pointer;
}
.returntoDashboard-dialogboxclose{
    cursor: pointer;
    float: right;
}
